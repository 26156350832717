:root {
  --back_grey: #EFF2F7;
  --back_white: #FFFFFF;

  --border_grey: #E0E3E7;

  --front_black: #0b131F;
  --front_grey: #A2A7B5;
  --front_white: #FFFFFF;

  --success: #39c642;
  --warning: #cac335;
  --error: #c74838;
  --light_error: #dcbfba;

  --neutral: #0C65FF;
  --neutral-light: #ECF5FF;

  --implicitconversion_back : #0B072C;
}

html, body {
  background-color: var(--implicitconversion_back);
  font-family: Helvetica, sans-serif;
  font-size: 14px;
  height: 100%;
  margin: 0;
  padding: 0;
  --NavBarHeight: 3em;
}

#Main {
  height: 100%;
}

#App {
  height: 100%;
  text-align: center;
}

/*===============================================================================================*/

.button {
  background-color: var(--neutral);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px 0px, rgba(0, 0, 0, 0.04) 0px 10px 10px 0px;
  box-sizing: border-box;
  color: var(--front_white);
  cursor: pointer;
  display: inline-block;
  font-weight: bold;
  margin: 0 1em 0 0;
  padding: 0.65em;
  text-decoration: none;
  white-space: nowrap;
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button > img, .button > .button-content > img {
  height: 1.5em;
}

.button.critical {
  background-color: var(--error);
}

.button > .icon,
.button > .addicon {
  height: 1.2em;
  vertical-align: middle;
  margin-right: 0.2em;
}


.buttongroup {
  display: inline-block;
}

.buttongroup .button {
  background-color: var(--back_white);
  border: solid 1px var(--border_grey);
  color: var(--front_grey);
  margin: 0;
}

.buttongroup .button.left {
  border-top-left-radius: 10px 10px;
  border-bottom-left-radius: 10px 10px;
  border-top-right-radius: 0px 0px;
  border-bottom-right-radius: 0px 0px;
}

.buttongroup .button.center {
  border-radius: 0px;
}

.buttongroup .button.right {
  border-top-left-radius: 0px 0px;
  border-bottom-left-radius: 0px 0px;
  border-top-right-radius: 10px 10px;
  border-bottom-right-radius: 10px 10px;
  margin-right: 1em;
}

.buttongroup .button.selected {
  background: var(--neutral-light);
  border: solid 1px var(--neutral);
  color: var(--neutral);
}
