.Filters {
	width: 100%;
  background-color: var(--back_white);
  border-radius: 10px;
  border: solid 1px var(--border_grey);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px 0px, rgba(0, 0, 0, 0.04) 0px 10px 10px 0px;
  box-sizing: border-box;
  margin-bottom: 1.5em;
  padding: 1em;
  text-align: left;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}

.Filters > .filterblock { 
	display: flex;  
	margin-bottom: 0.5em;
	align-items: center
}

.Filters > .filterblock > .label { 
	width: 100px;
	text-align: right;
	padding-right: 0.5em;
}

.Filters > .filterblock >  input[type=text] { 
	width: 200px;
}