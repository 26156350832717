.ReferenceImagePicker {
	border-radius: 10px;
	margin-bottom: 1em;
  background-color: var(--neutral);
  color: var(--front_white);
  font-size: 1em;
  font-weight: bold;
}

.ReferenceImagePicker > * > .title
{
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 0.5em;
}

.ReferenceImagePicker > * > .title > .title-text {
  display: inline-block;
}


.ReferenceImagePicker > * > .content{
	border: solid 1px var(--border_grey);
  background-color: var(--back_white);
  border-bottom-left-radius: 10px 10px;
  border-bottom-right-radius: 10px 10px;
  color: var(--front_black);
  display: flex;
  font-family: Menlo, Monaco, 'Courier New', Courier, monospace;
  font-size: 0.75em;
	font-weight: normal;
  justify-content: space-between;
	padding: 0.5em 1em;
	width: 100%;
}

.ReferenceImagePicker > .folded > .content{
	display: none;
}

.ReferenceImagePicker > * > .content > .image-array {
	overflow-x: scroll;
  width: 100%;
  white-space: nowrap;
}

.ReferenceImagePicker > * > .content > .image-array > img{
	height: 4em;
	margin-right: 0.5em;
  box-sizing:border-box;
  padding: 3px;
}

.ReferenceImagePicker > * > .content > .image-array > .selected{
  border: solid 3px var(--neutral);
}

.ReferenceImagePicker > * > .content > .image-add {
  height: 4em;
  margin-left: 1em;
  width: 6em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReferenceImagePicker > * > .content > .image-add > .button > .button-content > img {
  -webkit-filter: invert(1);
  filter: invert(1);
}