.InformationPanel{
  background-color: var(--back_white);
  height: calc(100vh - var(--NavBarHeight));;
  margin: 0;
  overflow-y: auto;
  padding: 1em;
  text-align: left;
  width: 70%;
}

.InformationBody {
  background-color: var(--back_white);
  height: 100%;
  width: 100%;
}
