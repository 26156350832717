.ImageThumbnail {
	background-color: var(--back_white);
  border: solid 1px var(--front_black);
	border-radius: 5px;
	color: var(--front_black);
	display: inline-block;
	font-size: 0.7em;
	margin: 1em;
	width: 260px;
}

.ImageThumbnail > div {
	padding: 10px;
}

.ImageThumbnail > .hasdifference {
	background-color: var(--error);
	color: var(--front_white);
}

.ImageThumbnail > .hasdifference.referenceupdated {
	background-color: var(--warning);
	color: var(--front_black);
}

.ImageThumbnail > * > .thumbnail {
	width: 240px;
	height: 90px;
}

.ImageThumbnail > * > .thumbnail > img {
	max-width: 100%;
	max-height: 100%;
}

.ImageThumbnail > * > .title {
	line-height: 1em;
	max-height: 2em;
	overflow: hidden;
	overflow-wrap: anywhere;
}