.NavBar {
  background-color: var(--neutral);
  color: var(--front_white);
  font-weight: bold;
  font-size: 1em;
  height: var(--NavBarHeight);
  line-height: var(--NavBarHeight);
  text-align: left;
  text-transform: uppercase;
  display: flex;
}

.NavBar a, .NavBar a:visited, .NavBar a:hover, .NavBar a:active {
	text-decoration: none;
  color: inherit;
}

.NavBarName {
  margin-right: 1em;
}

.NavBarName > * > img {
  height: var(--NavBarHeight);
}

.NavBarItem {
  cursor: pointer;
  margin-right: 1em;
  white-space: nowrap;
}

.NavBarItem.selected {
  box-shadow: inset 0 -0.3em var(--front_white);
}


.NavBarUser {
  display: flex;
  font-size: 0.75em;
  font-weight:initial;
	justify-content: flex-end;
  text-align: right;
  text-transform: lowercase;
  width: 100%;
}

.NavBarUser .username {
  cursor: auto;
	margin: 0 1em;
}

.NavBarUser .signout {
  cursor: pointer;
	margin: 0 1em;
}
