.ImageInspector {
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  cursor: default;
}

.ImageInspector.show{
  display: block !important;
}

.ImageInspector.hide{
  display: none !important;
}


.ImageInspector .backgroud-filter {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.ImageInspector .dialog {
  position: absolute;
  background-color: var(--back_white);
  width: 90%;
  max-height: 90%;
  left: 5%;
  top: 5%;
  border-radius: 10px;
  overflow-y: auto;
}

.ImageInspector .dialog .dialog-title {
  background-color: var(--neutral);
  color: var(--front_white);
  padding: 0.5em;
  font-weight: bold;
  border-top-left-radius: 10px 10px;
  border-top-right-radius: 10px 10px;
  border-bottom-left-radius: 0px 0px;
  border-bottom-right-radius: 0px 0px;
  display: flex;
  justify-content: space-between;
}

.ImageInspector .dialog .dialog-title .exitbutton {
  cursor: pointer;
}


.ImageInspector .dialog .dialog-content {
  margin: 1em;
  text-align: center;
}

.ImageInspector .dialog .dialog-information {
  margin: 1em;
}

.ImageInspector .tools {
  padding: 1em;
  border-bottom: solid 1px var(--border_grey);
  background-color: var(--back_grey);
  text-align: center;
}

.ImageInspector .tools .header {
  font-size: 1.25em;
  font-weight: bold;
  margin: 0;
}

.ImageInspector .information {
  margin: 0 1em;
} 

