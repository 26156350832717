[data-amplify-authenticator] {
	margin-top: 3em;
}

.amplify-divider--label {
	display: none;
}

[data-amplify-authenticator] [data-amplify-form] fieldset {
	display: none;
}

[data-amplify-authenticator] [data-amplify-form] .amplify-button--primary {
	display: none;
}

[data-amplify-authenticator] [data-amplify-footer] {
	display: none;
}