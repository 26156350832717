.ConfigList {
  background-color:var(--back_grey);
  border-right: 1px solid var(--front_grey);
  font-size: 1em;
  height: calc(100vh - var(--NavBarHeight));
  overflow-x:hidden;
  overflow-y: auto;
  position: relative;
  width: 30%;
  max-width: 400px;
}