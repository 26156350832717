.CommitList {
  background-color:var(--back_grey);
  border-right: 1px solid var(--front_grey);
  font-size: 1em;
  height: calc(100vh - var(--NavBarHeight));
  overflow-y: auto;
  position: relative;
  width: 30%;
  max-width: 500px;
}

.CommitList .title {
  font-size: 1.2em;
  font-weight: bold;
  margin: 0.3em 0;
}

.CommitList > .Filter {
  margin-bottom: 1.5em;
  text-align: center;
}

.CommitList > .Filter .option {
  background-color: var(--back_white);
  border: solid 1px var(--border_grey);
  box-sizing: border-box;
  color: var(--front_grey);
  cursor: pointer;
  display: inline-block;
  padding: 0.65em 0.65em;
  text-decoration: none;
}

.CommitList > .Filter .left {
  border-top-left-radius: 10px 10px;
  border-bottom-left-radius: 10px 10px;
}

.CommitList > .Filter .right {
  border-top-right-radius: 10px 10px;
  border-bottom-right-radius: 10px 10px;
}

.CommitList > .Filter .selected {
  background: var(--neutral-light);
  border: solid 1px var(--neutral);
  color: var(--neutral);
}



.CommitNode {
  background-color: var(--back_white);
  border-radius: 10px;
  border: solid 1px var(--border_grey);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px 0px, rgba(0, 0, 0, 0.04) 0px 10px 10px 0px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 1em;
  padding: 1em;
  text-align: left;
  overflow-x: hidden;
}

.CommitNode.selected {
  background-color: var(--neutral);
  color: var(--front_white);
}

.CommitNode .status {
  display: inline-block;
  font-weight: bold;
  line-height: 1.2em;
  text-transform: uppercase;
  vertical-align: top;
}

.CommitNode .cardname {
  display: inline-block;
  font-weight: bold;
  line-height: 1.2em;
  margin-left: 0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
  white-space: nowrap;
}

.CommitNode .giturl {
  text-decoration: none;
  display: inline-block;
}

.CommitNode .sha {
  font-size: 0.6em;
  font-family: monospace;
}

.CommitNode .timeago {
  font-size: 0.8em;
  color: var(--front_grey)
}

.CommitNode .line {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}

