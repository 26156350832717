.FoldableInformation {
	border-radius: 10px;
	margin-bottom: 1em;
  background-color: var(--neutral);
  color: var(--front_white);
  font-size: 1em;
  font-weight: bold;
}

.FoldableInformation > .empty {
	border-radius: 10px;
  background-color: var(--border_grey);
}

.FoldableInformation > * > .title
{
  display: flex;
  font-weight: bold;
  justify-content: space-between;
  padding: 0.5em;
}

.FoldableInformation > * > .title > .title-text {
  display: inline-block;
}

.FoldableInformation > * > .title > .title-clipboard {
  cursor: pointer;
}

.FoldableInformation > * > .content {
	border: solid 1px var(--border_grey);
  background-color: var(--back_white);
  border-bottom-left-radius: 10px 10px;
  border-bottom-right-radius: 10px 10px;
  color: var(--front_black);
  font-family: Menlo, Monaco, 'Courier New', Courier, monospace;
  font-size: 0.75em;
	font-weight: normal;
  overflow-x: auto;
	padding: 0.5em;
  white-space: pre;
}

.FoldableInformation > .folded > .content{
	display: none;
}

.FoldableInformation > .empty > .content{
	display: none;
}
