.CollapsibleImageTree, .CollapsibleImageTreeLine {
  font-family: Helvetica, sans-serif;
  font-size: 1rem;
  white-space: initial;
  margin-top: 1em;
}

.CollapsibleImageTreeLine {
  margin-top: 0;
}

.CollapsibleImageTreeLine > .folded > .content {
  display: none;
}

.CollapsibleImageTreeLine > .hasdifference > .title {
  background-color: var(--error);
  color: var(--front_white);
}

.CollapsibleImageTreeLine > .indent-1 {
  margin-left: 1em;
}

.CollapsibleImageTreeLine > .indent-2 {
  margin-left: 2em;
}

.CollapsibleImageTreeLine > .indent-3 {
  margin-left: 3em;
}

.CollapsibleImageTreeLine > .platform {
  margin-bottom: 1.5em;
}