
.CompareImageDifference > .header,
.CompareImageSlider > .header,
.CompareImageSideBySide > .header {
  font-weight: bold;
  width: 100%;
  margin: 1em;
}

.CompareImageSlider > .header > .left,
.CompareImageSideBySide > .header > .left {
  display: inline-block;
  text-align: left;
  margin-right: 25%;
}

.CompareImageSlider > .header > .right,
.CompareImageSideBySide > .header > .right {
  display: inline-block;
  text-align: right;
  margin-left: 25%;
}

.CompareImageDifference > .header > .downloadicon,
.CompareImageSlider > .header > * > .downloadicon,
.CompareImageSideBySide > .header > * > .downloadicon {
	cursor: pointer;
	height: 1.5em;
	margin-left: 0.5em;
	vertical-align: bottom;
}


/*===============================================================================================*/
/* img-comparison-slider */

img-comparison-slider.disappearing-sliders {
  --default-handle-width: 100px;
}

img-comparison-slider.disappearing-sliders:focus {
  --default-handle-opacity: 0;
}

.coloured-slider {
	--divider-color: #f0f;
	--default-handle-color: #f0f;
}