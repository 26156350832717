.ConfigListCard {
  background-color: var(--back_white);
  border-radius: 10px;
  border: solid 1px var(--border_grey);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px 0px, rgba(0, 0, 0, 0.04) 0px 10px 10px 0px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 1em;
  padding: 0.6em;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ConfigListCard.selected {
  background-color: var(--neutral);
  color: var(--front_white);
}

.ConfigListCard > .content {
  width: calc(30vw * 0.7);
}

.ConfigListCard > .content > .title {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ConfigListCard > .content > .count {
  display: block;
  font-size: 0.8em;
}

.ConfigListCard > .deleteicon {
  cursor: pointer;
  height: 1em;
  margin-right: 1em;
}

.ConfigListCard > .downloadicon, 
.ConfigListCard > .uploadicon {
  cursor: pointer;
  height: 1.5em;
  margin-right: 0.2em;
}

.ConfigListCard.selected > .downloadicon,
.ConfigListCard.selected > .uploadicon {
  filter: invert(1);
}


.ConfigListCard > .button {
  font-size: 0.75em;
}

.ConfigListCard > .confirmationcheck {
  font-size: 2em;
}

.ConfigListCard > .confirmationcheck.hidden {
  display: none;
}