.Tags {
	border: solid 1px var(--front_black);
	border-radius: 3px;
	flex-grow: 1;
	height: 2em;
	display: flex;
	justify-content: left;
	align-items: center;
	margin-bottom: 0.5em;
}

.Tags > .tag {
	display: inline-block;
	margin: 0.2em;
	background-color: var(--neutral);
	color: var(--front_white);
	font-size: 0.8em;
	padding: 0.1em 0.1em 0.1em 0.3em;
	border-radius: 5px;
}

.Tags > .tag > .delete {
	display: inline-block;
	margin: 0 0.3em;
	padding-left: 0.3em;
	border-left: solid 1px var(--front_white);
	cursor: pointer;
}

.Tags > input { 
	border: 0;
	outline: 0;
	width: 100%;
}

.Tags > input:focus { 
	outline: none!important;
}
