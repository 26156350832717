.VariableCard .addicon {
	height: 1.2em;
	cursor: pointer;
	vertical-align: middle;
}

.VariablesCard  {
	width: 100%;
  background-color: var(--back_white);
  border-radius: 10px;
  border: solid 1px var(--border_grey);
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px 0px, rgba(0, 0, 0, 0.04) 0px 10px 10px 0px;
  box-sizing: border-box;
  margin-bottom: 1.5em;
  padding: 1em;
  text-align: left;
}

.VariablesCard.dirty
{
	background-color: var(--neutral-light);
}

.VariablesCard > .row { 
	display: flex;  
	width: 100%;
}

.VariablesCard > .row > .label { 
	width: 20%;
	text-align: right;
	padding-right: 1em;
	vertical-align: middle;
}

.VariablesCard > .row > input[type=text] { 
	flex-grow: 1;
	margin-bottom: 0.5em;
}

.VariablesCard .deleteicon {
	align-self: center;
	cursor: pointer;
	height: 1.3em;
	margin-bottom: 0.5em;
	margin-left: 0.5em;
}